.custom-modal {
    display: none; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); 
    z-index: 10000; 
    justify-content: center;
    align-items: center;
  
    &.open {
      display: flex;
      animation: clipPathOpen 0.3s ease-out;
    }

    .after::after,.before::before {
        content: "";
        display: block
    }

    .close-button-holder {
        position: absolute;
        top: calc(3vw + 40px);
        right: calc(3vw + 40px);
        font-size: 0;
        line-height: 0;
        transform: translateX(180px) rotate(90deg);
        will-change: transform;
        transition: transform .8s cubic-bezier(.645, .045, .355, 1);
        transform: translateX(0) rotate(0);
        transition-delay: 2s
    }
    
    .close {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border: none;
        background: 0 0;
        display: block;
    }
    
    .close::after,.close::before {
        width: 2px;
        height: 48px;
        position: absolute;
        background-color: #fff;
        left: 50%;
        top: 50%;
        display: block;
        will-change: transform;
        transition: transform .8s cubic-bezier(.645, .045, .355, 1)
    }
    
    .close::after {
        transform: translate(-50%,-50%) rotate(45deg);
        transition-duration: .6s
    }
    
    .close::before {
        transform: translate(-50%,-50%) rotate(315deg)
    }
    
    .close:hover::after {
        transform: translate(-50%,-50%) rotate(225deg)
    }
    
    .close:hover::before {
        transform: translate(-50%,-50%) rotate(495deg)
    }



  }
  
  @keyframes clipPathOpen {
    0% {
      clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }
    100% {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
  
  .modal-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  
    iframe {
      width: 100%;
      height: 100%;
    }
  }