@mixin logoColor($sectionName, $fillColor) {
  &[rel="#{$sectionName}"] {
    .logo-wrapper.center {
      .logo {
        path.cls-1 {
          fill: $fillColor !important;
        }
      }
    }
  }
}

main {
  .logo-wrapper {
    &.center {
      &.de-active {
        display: none;
      }

      display: block;
      position: fixed;
      z-index: 110;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transform: translate(var(--x, 50%), var(--y, 50%));
      top: 0;
      left: 0;
      will-change: transform;

      .logo {
        pointer-events: all;
        max-width: 150px;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    &.top {
      position: relative;
      z-index: 110;
      pointer-events: none;
      height: 90px;
      .logo {
        filter: invert(1);
        opacity: 0;
        max-width: 100px;
        transform: translate3d(-200%, -25%, 0);
        transition: all ease-in-out 0.3s;
        will-change: transform;
        cursor: pointer;
      }
    }
  }

  @include logoColor("projects", $project-color);

  @include logoColor("awards", $awards-color);

  @include logoColor("skills", $skills-color);

  @include logoColor("contact", $contact-color);
}

.ready {
  .logo-wrapper.top {
    .logo {
      transform: translate3d(15%, -27%, 0);
      pointer-events: all;
      opacity: 1;
    }
  }
}

.back-to-home-animate {
  .logo-wrapper.top {
    .logo {
      transform: translate3d(-200%, -27%, 0);
      pointer-events: none;
    }
  }
}

@media (max-width: 960px) {
  main {
    .logo-wrapper {
      &.center {
        bottom:20px; 
        top:auto !important; 
        transform: none !important;
        --y: 0 !important;
        --x: 0 !important;
        width: 100%;
        height: 150px;
        text-align: center;
        .logo {transform: none !important;}
      }
      &.top {border-bottom: 1px solid #000; opacity: 0; transition: all .3s ease;}
    }
  }
  .ready {
    main {
      .logo-wrapper {
        &.top {opacity: 1;}
      }
    }
  }
  .back-to-home-animate {
    main {
      .logo-wrapper {
        &.top {opacity: 0;}
      }
    }
  }
}
