.hover-reveal {
  position: fixed;
  width: 400px;
  height: 400px;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}

.hover-reveal__inner,
.hover-reveal__img {
  width: 100%;
  height: 100%;
  position: relative;
}

.hover-reveal__deco {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #181314;
}

.hover-reveal__img {
  background-size: cover;
  background-position: 50% 50%;
}

@media (max-width: 960px) {
  .hover-reveal {display: none;}
}
